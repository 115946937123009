import { type QueryOptions } from "@tanstack/svelte-query";

import { localLoadValue, sessionLoadValue, sessionStoreValue } from "./local-persistence";

/**
 * Converts a JavaScript object into a URL query string format suitable for appending to URLs.
 * @param {Record<string, any>} params - The object containing the key-value pairs to be converted into a query string.
 * @returns {string} The generated query string.
 */
function objectToQueryString(params: Record<string, any>): string {
  const urlParams = new URLSearchParams();

  for (const key in params) {
    const value = params[key];

    if (Array.isArray(value)) {
      // Handle arrays by appending multiple values
      value.forEach(item => urlParams.append(key, item));
    } else {
      // Handle other values as single entries
      urlParams.set(key, value);
    }
  }

  return urlParams.toString();
}

/**
 * Defines options for GET API requests. Extendeing it to handle optional queryParams in the URL
 * @extends QueryOptions
 * @property {Record<string, any>?} queryParams - An object containing key-value pairs representing the query parameters to be included in the GET request.
 */
interface GetAPIQueryOptions extends QueryOptions {
  queryParams?: Record<string, any>;
}

/**
 * Fetches data from an API endpoint using a GET request.
 * @template ResponseModel - The type of the expected response data.
 * @param {GetAPIQueryOptions} options - Options for the fetch request, including the query key and optional query parameters.
 * @returns {Promise<ResponseModel>} A promise that resolves to the parsed response data.
 * @throws {Error} If an error occurs during the fetch request or if the response is not successful.
 */
export async function fetchRequest<ResponseModel>({ queryKey, queryParams }: GetAPIQueryOptions) {
  if (queryKey) {
    let url = queryKey.join("");
    // This is an optional parameter for GET method
    if (queryParams) {
      const urlParms = objectToQueryString(queryParams);
      url = url + '?' + urlParms.toString()
    }
    let response = await window.fetch("/api" + url, {
      headers: { "Authorization": "Bearer " + sessionLoadValue("auth.access_token", "") }
    });
    if (response.ok) {
      return (await response.json()) as ResponseModel;
    }
    if (response.status == 403) {
      response = await window.fetch("/auth/refresh", {
        headers: { "Authorization": "Bearer " + sessionLoadValue('auth.refresh_token', localLoadValue('auth.refresh_token', "")) as string }
      });
      if (response.ok) {
        sessionStoreValue("auth.access_token", await response.text());
        response = await window.fetch("/api" + url, {
          headers: { "Authorization": "Bearer " + sessionLoadValue("auth.access_token", "") }
        });
        if (response.ok) {
          return (await response.json()) as ResponseModel;
        }
      }
    }
  }
  throw new Error("Could not fetch data");
}

class MutationError extends Error {
  constructor(message: string, options: { cause: any }) {
    super(message, options);
  }
}

export async function mutationRequest<ResponseModel>(mutationKey: string[], method: string, data: ResponseModel, singleError: boolean) {
  let response = await window.fetch("/api" + mutationKey.join(""), {
    method: method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionLoadValue("auth.access_token", ""),
    },
  });
  if (response.ok) {
    if (response.status === 200) {
      return (await response.json()) as ResponseModel;
    } else {
      return null;
    }
  }
  if (response.status == 403) {
    response = await window.fetch("/auth/refresh", {
      headers: { "Authorization": "Bearer " + sessionLoadValue('auth.refresh_token', localLoadValue('auth.refresh_token', "")) as string }
    });
    if (response.ok) {
      sessionStoreValue("auth.access_token", await response.text());
      response = await window.fetch("/api" + mutationKey.join(""), {
        method: method,
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionLoadValue("auth.access_token", ""),
        },
      });
      if (response.ok) {
        if (response.status === 200) {
          return (await response.json()) as ResponseModel;
        } else {
          return null;
        }
      } else {
        if (singleError) {
          throw new Error(((await response.json()) as OpenApiError).detail.map((detail) => {
            return detail.msg[0].toUpperCase() + detail.msg.substring(1);
          }).join(" "));
        } else {
          const cause = {} as OpenAPIErrorDict;
          for (let detail of ((await response.json()) as OpenApiError).detail) {
            cause[detail.loc[1]] = detail;
          }
          throw new MutationError("Mutation failed.", { cause: cause });
        }
      }
    } else {
      throw new MutationError("You are not authorised for this action.", { cause: undefined })
    }
  } else {
    if (singleError) {
      throw new Error(((await response.json()) as OpenApiError).detail.map((detail) => {
        return detail.msg[0].toUpperCase() + detail.msg.substring(1);
      }).join(" "));
    } else {
      const cause = {} as OpenAPIErrorDict;
      for (let detail of ((await response.json()) as OpenApiError).detail) {
        cause[detail.loc[1]] = detail;
      }
      throw new MutationError("Mutation failed.", { cause: cause });
    }
  }
}
