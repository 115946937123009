<script lang="ts">
  import { Dialog } from "bits-ui";
  import { onDestroy, onMount, setContext } from "svelte";
  import { derived, writable } from "svelte/store";
  import { fade } from "svelte/transition";
  import { createQuery } from "@tanstack/svelte-query";

  import { location } from "./simple-svelte-router";
  import Route from "./simple-svelte-router/Route.svelte";

  import { fetchRequest } from "./lib/api";
  import {
    localLoadValue,
    sessionLoadValue,
    sessionStoreValue,
  } from "./lib/local-persistence";
  import SkipToContent from "./components/ui/SkipToContent.svelte";
  import HeaderBar from "./components/ui/HeaderBar.svelte";
  import LoadingStatusEntry from "./components/ui/LoadingStatusEntry.svelte";
  import MessagesPopup from "./components/messages/MessagesPopup.svelte";
  import SubPageLoadingIndicator from "./components/ui/SubPageLoadingIndicator.svelte";
  import OUHeader from "./ou-branding/OUHeader.svelte";
  import OUFooter from "./ou-branding/OUFooter.svelte";
  import Launcher from "./routes/Launcher.svelte";
  import Logout from "./routes/Logout.svelte";

  let AdminComponent: any = null;
  let MessagesComponent: any = null;
  let UserComponent: any = null;
  let DashboardComponent: any = null;

  const locationUnsubscribe = location.subscribe((location) => {
    if (location.pathname.startsWith("/admin")) {
      if (AdminComponent === null) {
        import("./routes/Admin.svelte").then((module) => {
          AdminComponent = module.default;
        });
      }
    } else if (location.pathname.startsWith("/messages")) {
      if (MessagesComponent === null) {
        import("./routes/Messages.svelte").then((module) => {
          MessagesComponent = module.default;
        });
      }
    } else if (location.pathname.startsWith("/user")) {
      if (UserComponent === null) {
        import("./routes/User.svelte").then((module) => {
          UserComponent = module.default;
        });
      }
    } else if (location.pathname.startsWith("/dashboard")) {
      if (DashboardComponent === null) {
        import("./routes/Dashboard.svelte").then((module) => {
          DashboardComponent = module.default;
        });
      }
    }
  });

  async function refreshAccessToken() {
    const response = await window.fetch("/auth/refresh", {
      headers: {
        Authorization: ("Bearer " +
          sessionLoadValue(
            "auth.refresh_token",
            localLoadValue("auth.refresh_token", ""),
          )) as string,
      },
    });
    if (response.ok) {
      sessionStoreValue("auth.access_token", await response.text());
    }
  }
  const accessTokenRefreshInterval = window.setInterval(
    refreshAccessToken,
    270000,
  );
  const ouMenuOpen = writable(false);
  const ouMenuObserver = new MutationObserver((changes) => {
    for (let change of changes) {
      ouMenuOpen.set(
        (change.target as HTMLElement).classList.contains("ou-toggle"),
      );
    }
  });

  onMount(() => {
    refreshAccessToken();
    const ouHeaderNav = document.querySelector("#ou-header-nav");
    if (ouHeaderNav) {
      ouMenuObserver.observe(ouHeaderNav, {
        attributes: true,
        attributeFilter: ["class"],
      });
    }
  });
  onDestroy(() => {
    locationUnsubscribe();
    window.clearInterval(accessTokenRefreshInterval);
    ouMenuObserver.disconnect();
  });

  const apiStatus = createQuery({
    queryKey: ["/"],
    queryFn: fetchRequest<APIStatus>,
    refetchInterval: 60000,
  });

  const config = createQuery({
    queryKey: ["/config"],
    queryFn: fetchRequest<Config>,
    refetchInterval: 60000,
  });

  const user = createQuery({
    queryKey: ["/users", "/current"],
    queryFn: fetchRequest<User>,
    retry: false,
    refetchInterval: 60000,
  });

  const groups = createQuery({
    queryKey: ["/groups"],
    queryFn: fetchRequest<Group[]>,
    refetchInterval: 60000,
  });

  const vceOptions = derived(user, (user) => {
    return {
      queryKey: ["/users", "/" + user.data?.id, "/vce"],
      queryFn: fetchRequest<CurrentVCE>,
      refetchInterval: 30000,
      retry: false,
      enabled: user.isSuccess,
    };
  });
  const vce = createQuery(vceOptions);

  const messages = createQuery({
    queryKey: ["/messages"],
    queryFn: fetchRequest<Message[]>,
    refetchInterval: 60000,
  });

  const seenMessages = createQuery({
    queryKey: ["/messages", "/seen"],
    queryFn: fetchRequest<number[]>,
    refetchInterval: 60000,
  });

  const isAdmin = derived([user, groups], ([user, groups]) => {
    if (groups.isSuccess && user.isSuccess) {
      for (let group of groups.data) {
        if (group.name === "admin") {
          if (user.data.groups.indexOf(group.id) >= 0) {
            return true;
          }
        }
      }
    }
    return false;
  });

  const showLoadingDialog = derived(
    [apiStatus, config, user],
    ([apiStatus, config, user]) => {
      return !apiStatus.isSuccess || !config.isSuccess || !user.isSuccess;
    },
  );
  const showUnavailableDialog = derived(apiStatus, (apiStatus) => {
    return apiStatus.isError || (apiStatus.isSuccess && !apiStatus.data.ready);
  });
  const showLoggedOutDialog = derived(
    [apiStatus, config, user],
    ([apiStatus, config, user]) => {
      return apiStatus.isSuccess && config.isSuccess && user.isError;
    },
  );

  setContext("useAPIStatus", apiStatus);
  setContext("useConfig", config);
  setContext("useUser", user);
  setContext("useGroups", groups);
  setContext("useVce", vce);
  setContext("useIsAdmin", isAdmin);
  setContext("useMessages", messages);
  setContext("useSeenMessages", seenMessages);
  setContext("useOuMenuOpen", ouMenuOpen);
</script>

<svelte:head>
  <title
    >{$apiStatus.isPending || $config.isPending || $user.isPending
      ? "Loading Configuration. Please wait..."
      : $config.isSuccess
        ? $config.data.title
        : ""}</title
  >
</svelte:head>

<SkipToContent />
<OUHeader />
<HeaderBar />
<MessagesPopup />
<Route path="/">
  <Launcher />
</Route>
{#if $isAdmin}
  <Route path="/admin/*">
    {#if AdminComponent !== null}
      <svelte:component this={AdminComponent} />
    {:else}
      <SubPageLoadingIndicator
        >Loading the admin interface</SubPageLoadingIndicator
      >
    {/if}
  </Route>
  <Route path="/dashboard/*">
    {#if DashboardComponent !== null}
      <svelte:component this={DashboardComponent} />
    {:else}
      <SubPageLoadingIndicator
        >Loading the dashboard information</SubPageLoadingIndicator
      >
    {/if}
  </Route>
{/if}
<Route path="/messages/*">
  {#if MessagesComponent !== null}
    <svelte:component this={MessagesComponent} />
  {:else}
    <SubPageLoadingIndicator
      >Loading the notifications information</SubPageLoadingIndicator
    >
  {/if}
</Route>
<Route path="/user/*">
  {#if UserComponent !== null}
    <svelte:component this={UserComponent} />
  {:else}
    <SubPageLoadingIndicator
      >Loading the user information</SubPageLoadingIndicator
    >
  {/if}
</Route>
<Route path="/logout">
  <Logout />
</Route>
<OUFooter />

<Dialog.Root
  open={$showLoadingDialog}
  closeOnEscape={false}
  closeOnOutsideClick={false}
>
  <Dialog.Trigger class="hidden" />
  <Dialog.Portal data-ocl-dialog-blocking="">
    <Dialog.Overlay transition={fade} />
    <Dialog.Content>
      <Dialog.Title>Loading. Please wait...</Dialog.Title>
      <ul data-ocl-dialog-content="">
        <LoadingStatusEntry
          query={apiStatus}
          label="Checking for API availability"
        />
        <LoadingStatusEntry query={config} label="Fetching the configuration" />
        <LoadingStatusEntry query={user} label="Authenticating" />
      </ul>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>

<Dialog.Root
  open={$showUnavailableDialog}
  closeOnEscape={false}
  closeOnOutsideClick={false}
>
  <Dialog.Trigger class="hidden" />
  <Dialog.Portal data-ocl-dialog-blocking="">
    <Dialog.Overlay transition={fade} />
    <Dialog.Content>
      <Dialog.Title>The System is currently unavailable</Dialog.Title>
      <p data-ocl-dialog-content="">
        The system is currently not available. This is generally a temporary
        state and the system will automatically check when it becomes available
        again.
      </p>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>

<Dialog.Root
  open={$showLoggedOutDialog}
  closeOnEscape={false}
  closeOnOutsideClick={false}
>
  <Dialog.Trigger class="hidden" />
  <Dialog.Portal data-ocl-dialog-blocking="">
    <Dialog.Overlay transition={fade} />
    <Dialog.Content>
      <Dialog.Title
        >You have been logged out of the {#if $config.isSuccess}{$config.data
            ?.title}{/if}</Dialog.Title
      >
      <div data-ocl-dialog-content="">
        {#if $config.isSuccess}
          <p class="mb-2">
            You have been logged out of the {$config.data.title}. If you want to
            access the {$config.data.title} again, then please return to the VLE
            from which you accessed the {$config.data.title} and log in again.
          </p>
          {#if $config.data && $config.data.vle.url}
            <p class="text-right">
              <a href={$config.data.vle.url} data-ocl-button=""
                >Return to the VLE</a
              >
            </p>
          {/if}
        {/if}
      </div>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
